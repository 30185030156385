.article-preview {
    flex: 1; 
    max-width: 300px;  /* Adjust the max width as needed */
    min-height: 300px; /* Adjust the min height as needed */
    background-color: #89BD9C;
    padding: 25px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #482317;
    transition: transform .2s;
    border: 1.25px solid #482317;
}


.article-preview:hover {
    transform: scale(1.05); /* This creates a zoom effect on hover */
}

.article-preview-title {
    font-family: 'Favorit', sans-serif;
    font-size: 1.30rem;
    letter-spacing: -.02em;
    line-height: 1;
}

.article-preview-subtitle {
    font-family: 'Favorit', sans-serif;
    font-size: 0.9rem;
    letter-spacing: -.02em;
    line-height: 1;
}

.article-preview-button {
    margin-left: auto;
    background-color: #F4D98C;
    padding: 10px 20px;
    color: #482317;
    border: 1.25px solid #482317;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 20px; /* Add space at the top */
    padding: 10px 20px; /* Increase button's own size */
    margin-bottom: 15px; 
    display: inline-block;
  }
.article-preview-button:hover {
    background-image: linear-gradient(78.19deg, #89BD9C 9.16%, #61a0af 29.27%, #f06c9b 47.56%, #f9b9b7 64.01%, #f5d491 79.37%);
    cursor: pointer;
  
  }