.success-container {
    background-color: #96c9dc; /* replace with the actual color you want */
    border: 1px solid #482317; /* replace with the actual color you want */
    padding: 20px;
    margin: 1em 1em; /* add horizontal margin */
    border-radius: 10px; /* if you want rounded corners */
    box-sizing: border-box; /* to include the padding and border in the element's total width */
    width: 90%; /* reduce the width to create horizontal space */
    max-width: 600px; /* optional, to prevent the form from becoming too wide on large screens */
    margin-left: auto;
    margin-right: auto;
      
}

.success-title {
    font-family: 'Tobias', serif;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
    color: #482317;
    margin-bottom: 20px; /* adjust this value as per your requirement */

}

.success-subtitle {
    margin-top: 0; /* this will remove the default top margin */
}