.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 1%; /* 3% top and bottom padding, 2% left and right padding */
    flex-grow: 1;
    background-color: #faf7f0;
    color: #482317; 
    font-size: 1.3rem;
    max-width: 1000px; 
    margin: auto; 
}

  
.home-title {
    font-family: 'Tobias', serif;
    font-size: 3.3rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
    margin-block-start: 0.67em;
    margin-block-end: 0.37em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

/*mark {
    background-color: #faf7f0;
    font-style: italic;
    margin-left: 0.02rem;
    margin-right: 0.3rem;
    background-color: rgb(150, 201, 220, 0.45);
}
mark:hover {
    background-color: rgb(150, 201, 220, 0.45);
}*/



.website-name {
    color: #f06c9b;
    margin-right: 0.75rem;
    font-style: italic;
}


.home-text {
    text-align: center; /* align text to the left */
    width: 100%; /* ensure it takes the full width of the parent */
}

.home-main-content {
    border: 1px solid black; /* Change according to your preference */
    padding: 50px;
    margin: 20px 15px;
    border-radius: 10px;
    background-color: #fff; /* replace with the actual color you want */
    border: 1px solid #482317;
}

.home-tagline {
    display: block;       /* Convert <b> to block-level */
    text-align: center;   /* Center the text */
}


.home-container {
    padding-top: 60px;
    margin: 0px 15px;
}


.home-about-button {
    margin-left: auto;
    background-color: #F4D98C;
    padding: 10px 20px;
    font-size: 0.9rem;
    color: #482317;
    border: 1.25px solid #482317;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 40px; /* Add space at the top */
  }
  
  
  .home-about-button:hover {
    background-image: linear-gradient(78.19deg, #89BD9C 9.16%, #61a0af 29.27%, #f06c9b 47.56%, #f9b9b7 64.01%, #f5d491 79.37%);
    cursor: pointer;
  
  }
  
  .glow-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: red;  /* Very visible color */
    z-index: -1;
}

@media screen and (min-width: 390px) {
    .home {
        padding: 60px 15px;
    }
    
}