.cheatsheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8% 1%; 
    flex-grow: 1;
    background-color: #faf7f0;
    color: #482317; 
    font-size: 1.3rem;
    max-width: 1000px; 
    margin: auto; 
}

.cheatsheet-title {
    font-family: 'Tobias', serif;
    font-size: 3.375rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
}

.cheatsheet-subtitle {
    font-size: 1.1em;
}

.cheatsheet-content {
    border: 1px solid black; 
    padding: 35px;
    margin: 20px 15px;
    border-radius: 10px;
    background-color: #fff; 
    border: 1px solid #482317;
    text-align: left; 
}

.cheatsheet-content a {
    color:#1b4d62 !important; 
    text-decoration: underline; 
}

.cheatsheet-content ul.source-list {
    list-style-type: none; 
    padding-left: 0; 
}

.term-title {
    font-size: 2rem;
}

.source-text {
    font-size: 1.1rem;
}

.source-list {
    font-size: 1rem;
}

.source-list li {
    margin-bottom: 0.5em; /* adjust this value to increase or decrease the spacing */
}


.definition {
    margin-bottom: 2.2em; /* adjust this value to increase or decrease the spacing */
}

@media screen and (min-width: 390px) {
    .cheatsheet {
        padding: 60px 0px;
    }
    
}