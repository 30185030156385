.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8% 1%; /* 3% top and bottom padding, 2% left and right padding */
    flex-grow: 1;
    background-color: #faf7f0;
    color: #482317; 
    font-size: 1.3rem;
    max-width: 1000px; 
    margin: auto; 
}

.about-title {
    font-family: 'Tobias', serif;
    font-size: 3.375rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
}

.about-content {
    border: 1px solid black; /* Change according to your preference */
    padding: 20px;
    margin: 20px 15px;
    border-radius: 10px;
    background-color: #fff; /* replace with the actual color you want */
    border: 1px solid #482317;
}

.about-text {     /* Convert <b> to block-level */
    text-align: center; 
}

@media screen and (min-width: 390px) {
    .about {
        padding: 60px 0px;
    }
    
}