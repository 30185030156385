.media-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8% 1%; 
    flex-grow: 1;
    background-color: #faf7f0;
    color: #482317; 
    font-size: 1.3rem;
    max-width: 1000px; 
    margin: auto; 
}

.media-title {
    font-family: 'Tobias', serif;
    font-size: 3.375rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
}

.media-subtitle {
    font-size: 1.1em;
}

.media-content {
     
}

.instagram-section {
    border: 1px solid black; 
    padding: 35px;
    margin: 20px 15px;
    border-radius: 10px;
    background-color: #fff; 
    border: 1px solid #482317;
    text-align: left;
}

.tiktok-section {
    border: 1px solid black; 
    padding: 35px;
    margin: 20px 15px;
    border-radius: 10px;
    background-color: #fff; 
    border: 1px solid #482317;
    text-align: left;
}
/* Update existing styles */
.instagram-section, .tiktok-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
    padding: 20px;
}

.instagram-section img, .tiktok-section img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 300px;
}
