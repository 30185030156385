.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  position: relative;
  background-color: #faf7f0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}


.marquee {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #f9b9b7;
  color: #482317;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
}

.marquee-content {
  display: flex;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}

/* Duplicate the content */
.marquee-content::before,
.marquee-content::after {
  content: attr(data-text);
  padding-right: 50px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.Navbar {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: 80px;
  padding: 40px 30px;
  color: #482317;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.25px solid #482317;
  box-sizing: border-box;
  background-color: #faf7f0;
}


.logo {
  height: 140px;  /* Adjust this to suit your needs */
  width: auto;
  margin-right: 20px;
}

.logo-link {
  display: inline-block; /* if needed */
}

.logo-link .logo {
  /* Your logo styling here */
}


.nav-link {
  color: #482317; /* replace with your desired color */
  text-decoration: none; /* remove underline */
  /* add any other styles you want here */
  
}


.nav-links {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 40px;
  list-style: none;
  margin-left: auto;
  font-size: 1.1em;
}

.nav-links.show {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #faf7f0;
  border: none;
  padding: 20px;
  border-radius: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.nav-links.show li {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1em;
}

.nav-links li {
  font-family: 'Favorit', sans-serif;
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
}

.nav-links.show a {
  color: #482317;
}


.website-button {
  margin-left: auto;
  background-color: #F4D98C;
  padding: 10px 20px;
  color: #482317;
  border: 1.25px solid #482317;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  text-decoration: none;
}


.website-button:hover {
  background-image: linear-gradient(78.19deg, #89BD9C 9.16%, #61a0af 29.27%, #f06c9b 47.56%, #f9b9b7 64.01%, #f5d491 79.37%);
  cursor: pointer;

}


.overflow-hidden {
  overflow: hidden;
}

.menu-icon {
  width: 40px;  
  height: 30px; 
  display: none;  /* change default display to none */
  justify-content: center;
  align-items: center;
}

.close-icon {
  display: none; /* make sure close icon is hidden by default */
}

@media screen and (max-width: 1025px) {
  .menu-icon {
    display: flex; /* show menu icon on smaller screens */
  }

  .nav-links {
    display: none; /* hide nav links on smaller screens */
  }

  .nav-links.show {
    /* display fullscreen menu */
  }

  .close-icon.show {
    display: flex; /* show close icon when menu is open and screen is small */
  }
}

@media screen and (min-width: 769px) {
  .close-icon {
    display: none !important; /* hide close icon on larger screens, even if 'show' class is present */
  }
}

.hamburger-icon.show {
  display: flex; /* show hamburger icon when menu is closed */
}

.home-container {
  padding-top: 0;  /* This will be dynamically updated by the JS code */
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropbtn {
  background: none;
  color: #482317; /* replace with your desired color */
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dropdown .dropbtn i {
  margin-left: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  background-color: #faf7f0;
  color: #482317;
}

.dropdown-content a {
  color: #482317;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  color: #f06c9b; /* replace 'pink' with the specific color code if necessary */
}



.dropdown:hover .dropdown-content {
  display: block;
}






.coming-soon-title {
  font-size: 90px;
  font-family: 'Favorit', sans-serif;
}

.coming-soon {
  color: #482317;
  font-family: 'Tobias', serif;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.02;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #faf7f0;
}

.left-section,
.middle-section,
.right-section {
  flex: 1;
  text-align: center;
}

.middle-section {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer .middle-section a {
  color: #482317;
  transition: color 0.3s ease;
}

.footer .middle-section a:hover {
  color: #9D94C0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (min-width: 390px) {
  .Navbar {
      padding: 30px 15px 30px 0px
  }

  .footer {
        padding-right: 20px;
    }
    
}
  
@media screen and (min-width: 390px) {
  .nav-links.show {
      padding: 0px;
  }
  
}