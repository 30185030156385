.medium-container {
    padding: 8% 1%; 
    background-color: #faf7f0;
    color: #482317; 
    max-width: 1000px; 
    margin: auto; 
}

.medium {
    display: flex;
    flex-direction: row; /* Arrange children (Article Previews) in a row */
    justify-content: center; 
    align-items: stretch; /* Make children (Article Previews) stretch to have equal height */
    padding: 8% 1%;
    /*flex-grow: 1;*/
    background-color: #faf7f0;
    color: #482317; 
    max-width: 1000px; 
    margin: auto; 
}


.medium-title {
    font-family: 'Tobias', serif;
    font-size: 3.375rem;
    font-weight: 300;
    letter-spacing: -.02em;
    line-height: 1.025;
    text-align: center; /* To center-align the text */
}

@media (max-width: 720px) {
    .medium {
        flex-direction: column;
        align-items: center; /* This will center the flex items (children) on the cross axis (vertically, in this case) */
    }
    /* If individual items have specific widths that prevent centering, you might need to adjust or override those styles as well */
    .medium .article { /* Assuming your article previews have a class of 'article' */
        width: 100%; /* Make each article preview full width */
        max-width: 1000px; /* Or whatever maximum width you prefer */
        margin: 0 auto; /* This will apply automatic margins on both sides, centering the block-level elements */
    }
}

@media screen and (min-width: 390px) {
    .medium-container {
        padding: 60px 0px;
    }
    
}